import React from "react"
import { wrapper, siteTitle, nav } from "./index.module.css"

import Link from "gatsby-link"
import Seo from "../components/SEO"

const IndexPage = props => (
  <>
    <Seo />
    <main>
      <div className={wrapper}>
        <div className={siteTitle}>
          <h1>Ryan O'Shea</h1>
          <p>
            Software Engineer
            <br />
            Berlin
          </p>
        </div>
        <ul className={nav}>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
      </div>
    </main>
  </>
)

export default IndexPage
